import React, { Fragment, useEffect, useState } from 'react'
import { PageHeader } from 'antd';

import Register from '../../components/Members/Register'



const Members = () => {

    const routes = [
        {
            path: '',
            breadcrumbName: 'ลงทะเบียน - Register',
        },
    ];


    return (
        <Fragment>
            <PageHeader
                className="site-page-header"
                title="ลงทะเบียน - Register"
                breadcrumb={{ routes }}
                subTitle="ลงทะเบียนสมาชิกใหม่"
            />

           
                    <Register />

        </Fragment>
    )
}

export default Members