import React, { Fragment, useEffect, useState } from 'react'
// import ViewProfile from '../../components/Profile/View'
// import SendAPI from '../../utils/SendAPI'
// import useAuth from '../../utils/Auth'
// import { useLiff } from 'react-liff';
import liff from '@line/liff';

interface LINEProfile {
    userId: string;
    displayName: string;
    pictureUrl?: string;
    statusMessage?: string;
}

const Page1 = () => {
    const [lineProfile, setLineProfile] = useState<LINEProfile>()
    // const [lineProfile, setLineProfile] = useState<any>()
    const [token, setToken] = useState('')
    const [errorsss, setErrorsss] = useState('')

    const line = async () => {
        await liff.init({ liffId: `1657085161-LGQzvRVo` }, async () => {

            if (liff.isLoggedIn()) {
                // scanCode call
                setToken(liff.getAccessToken() || "")
                liff.getProfile().then(profile => {
                    // setLineProfile({
                    //     displayName: profile.displayName,
                    //     userId: profile.userId,
                    //     pictureUrl: profile.pictureUrl,
                    //     statusMessage: profile.statusMessage,
                    // })
                    setLineProfile(profile)
                }).catch(err => setErrorsss(err));
            } else {
                await liff.login();
            }

        }, err => console.error(err))
    }

    useEffect(() => {
        line()
    }, [])

    return (
        <>
            name: {lineProfile?.displayName}
            <br />
            userLineID: {lineProfile?.userId}
            <br />
            pictureUrl: {lineProfile?.pictureUrl}
            <br />
            statusMessage: {lineProfile?.statusMessage}
            <br />
            token: {token}
            <br />
            errorsss: {errorsss}
            <br />
            Page X
            {/* <div className="pt-3 pt-lg-5">
                <ViewProfile
                    data={getMe.data}
                    allows={getMe.allows}
                />
            </div> */}
        </>
    )
}

export default Page1