import { Route, Routes, Navigate } from 'react-router-dom'
import { Error403, Error404 } from '../views/Error'
import Members from '../views/Members'
import Page1 from '../views/Page1'
import Page2 from '../views/Page2'


const AppLayout = () => {
    

    return (
        <>
            <Routes>
                {/* route pages */}
                <Route path="/page1" element={<Page1 />} />
                <Route path="/page2" element={<Page2 />} />
                <Route path="/register" element={<Members />} />

                {/* route errors */}
                <Route path="/403" element={<Error403 />} />
                <Route path="/404" element={<Error404 />} />

                <Route
                    path="*"
                    element={<Navigate to="/404" replace />}
                />
            </Routes>
        </>
    )
}

export default AppLayout