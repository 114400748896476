import React from 'react';
import './App.css';
import './custom.css';
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import AppLayout from './layouts/App'
import 'antd/dist/antd.css'


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<AppLayout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
