const Error403 = () => {
    return (
        <>
            Error: 403
        </>
    )
}

const Error404 = () => {
    return (
        <>
            Error: 404
        </>
    )
}

export {
    Error404, 
    Error403,
}